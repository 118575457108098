import { Strings } from '@boommed-suite/typescript-crossplatform'
import { Fab, Grid, Stack, type SxProps, type Theme } from '@mui/material'
import React from 'react'
import { Link } from 'react-router-dom'
import { AppHeader } from '../../components/AppHeader/AppHeader'
import { styles } from './MasterPage.styles'
import { MasterDrawer } from './components/MasterDrawer'

export interface MasterPageAction {
  icon: React.ReactNode
  href?: string
  title?: string
  sx?: SxProps<Theme>
  onClick?: () => void
}

interface MasterPageProps {
  title?: string
  children?: React.ReactNode
  actions?: MasterPageAction[]
  menuOpen?: boolean
}

const MasterPageActionButton = ({
  href,
  icon,
  title,
  onClick,
  sx,
}: MasterPageAction) => (
  <Link to={href ?? Strings.empty()}>
    <Fab
      title={title}
      color="primary"
      aria-label={title}
      sx={sx ?? styles.actionsButton}
      onClick={() => onClick?.()}
    >
      {icon}
    </Fab>
  </Link>
)

export const MasterPage = ({
  title,
  children,
  actions = [],
  menuOpen = false,
}: MasterPageProps) => {
  return (
    <>
      <AppHeader title={title} />
      <Grid container sx={styles.actionsContainer}>
        <Grid item xs justifyContent="flex-end" container sx={styles.actions}>
          <Stack direction="row" spacing={2}>
            {actions.map((action, index) => (
              <MasterPageActionButton key={index} {...action} />
            ))}
          </Stack>
        </Grid>
        <Grid item xs={1} />
      </Grid>

      <MasterDrawer open={menuOpen}>{children}</MasterDrawer>
    </>
  )
}
