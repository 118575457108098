import { Grid2, IconButton, Typography } from '@mui/material'
import React, { useCallback } from 'react'
import { AppRoutes, useAppNavigate } from '../../app/AppRouter'
import { BoommedSuiteSmallLogoColor } from '../../assets/BoommedSuiteSmallLogoColor'
import { UserProfile } from '../UserProfile/UserProfile'
import { styles } from './AppHeader.styles'

interface AppHeaderProps {
  title?: string
}

export const AppHeader = ({ title }: AppHeaderProps = {}) => {
  const navigate = useAppNavigate()

  const onMyTenantsClick = useCallback(() => {
    navigate(AppRoutes.tenants)
  }, [])

  return (
    <Grid2 sx={styles.root} container>
      <Grid2 sx={{ flexGrow: 1 }} container spacing={2}>
        <Grid2>
          <IconButton onClick={onMyTenantsClick}>
            <BoommedSuiteSmallLogoColor />
          </IconButton>
        </Grid2>
        <Grid2 alignContent="center">
          <Typography variant="h4">{title}</Typography>
        </Grid2>
      </Grid2>
      <Grid2>
        <UserProfile />
      </Grid2>
    </Grid2>
  )
}
