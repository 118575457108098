import { type SuiteApi } from '@boommed-suite/contracts'
import {
  Guid,
  HttpRequestError,
  NetworkError,
  StatusCode,
  Strings,
  isError,
} from '@boommed-suite/typescript-crossplatform'
import { useInjection } from 'inversify-react'
import React, { useCallback, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { BoommedService } from '../../domain/services/BoommedService'
import { Crashlytics } from '../../domain/services/Crashlytics'
import { useAppNavigate } from '../app/AppRouter'
import { useAppContext } from '../app/contexts/AppContext'
import { useNotificationContext } from '../app/contexts/NotificationContext'
import { TemplatedForm } from '../components/TemplatedForm/TemplatedForm'
import { MasterPage } from './MasterPage/MasterPage'

interface SubmitValues {
  [key: string]: unknown
  phoneNumber: string
}

export const AddClientPage = () => {
  const { t } = useTranslation()
  const { menu } = useAppContext()
  const boommedService = useInjection(BoommedService)
  const { openNotification } = useNotificationContext()
  const crashlytics = useInjection(Crashlytics)
  const navigate = useAppNavigate()

  const [unexpectedError, setUnexpectedError] = useState<Error>()
  if (unexpectedError) {
    throw unexpectedError
  }

  const addClient = useCallback(async (values: SuiteApi.AddClientRequest) => {
    if (menu?.items?.clients?._links?.new) {
      const [result, error] = await boommedService.fetch(
        menu.items.clients._links.new,
        values,
      )

      if (
        isError(HttpRequestError)(error) &&
        // eslint-disable-next-line @typescript-eslint/no-unsafe-enum-comparison
        error.statusCode === StatusCode.BAD_REQUEST
      ) {
        openNotification({
          severity: 'error',
          text: t('client_duplicate_error'),
        })
        return
      }

      if (isError(NetworkError)(error)) {
        setUnexpectedError(error)
        return
      }

      if (isError(Error)(error) || isError(HttpRequestError)(error)) {
        crashlytics.error(error)
        openNotification({
          severity: 'error',
          text: t('client_add_error'),
        })
        return
      }

      if (result) {
        navigate(-1)
      }
    }
  }, [])

  return (
    <MasterPage title={t('clients_add')}>
      <TemplatedForm<SubmitValues>
        fields={menu?.items?.clients?.data?.templates?.new}
        onSubmit={(values) => {
          const newClient = {
            ...values,
            phoneNumber: Strings.isNullOrEmpty(values.phoneNumber?.trim())
              ? undefined
              : [values.phoneNumber.trim()],
            userId: Guid.New().toString(),
            externalLink: 'some external link',
          } as SuiteApi.AddClientRequest

          void addClient(newClient)
        }}
        submitText={t('clients_add')}
      />
    </MasterPage>
  )
}
