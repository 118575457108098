import { type SuiteApi } from '@boommed-suite/contracts'
import { Strings, type Link } from '@boommed-suite/typescript-crossplatform'
import AddIcon from '@mui/icons-material/Add'
import DeleteIcon from '@mui/icons-material/Delete'
import SearchIcon from '@mui/icons-material/Search'
import {
  IconButton,
  InputBase,
  Link as MaterialLink,
  Paper,
  TablePagination,
  Typography,
} from '@mui/material'
import {
  DataGrid,
  type GridFilterModel,
  type GridRowParams,
  type GridRowSelectionModel,
  type GridSortModel,
} from '@mui/x-data-grid'
import { ClearIcon } from '@mui/x-date-pickers'
import { useInjection } from 'inversify-react'
import React, { useCallback, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { BoommedService } from '../../../domain/services/BoommedService'
import { AppRoutes, buildNavigationPath } from '../../app/AppRouter'
import { useAppContext } from '../../app/contexts/AppContext'
import { useService } from '../../hooks/useService'
import { useTenantDeepLink } from '../../hooks/useTenantDeepLink'
import { MasterPage, type MasterPageAction } from '../MasterPage/MasterPage'
import { styles } from './ClientsPage.styles'

export const ClientsPage = () => {
  const boommedService = useInjection(BoommedService)
  const { menu, tenant } = useAppContext()
  const { t } = useTranslation()

  const [refreshClients, setRefreshClients] = useState<boolean>(true)
  const [clients, setClients] = useState<SuiteApi.ClientsListResponse>()
  const [selectedClients, setSelectedClients] = useState<
    SuiteApi.ClientDetailResponse[]
  >([])
  const [selectedPage, setSelectedPage] = useState<number>(0)
  const [pageChange, setPageChange] = useState<Link | null>(null)
  const [searchTerm, setSearchTerm] = useState<string>(Strings.empty())
  const [sortField, setSortField] = useState<string>(Strings.empty())
  const [sortOrder, setSortOrder] = useState<string>(Strings.empty())
  const [filterModel, setFilterModel] = useState<GridFilterModel>()

  const [unexpectedError, setUnexpectedError] = useState<Error>()
  if (unexpectedError) {
    throw unexpectedError
  }

  useTenantDeepLink()

  const { fetching } = useService<SuiteApi.ClientsListResponse>(
    {
      service: async () => {
        if (menu?.items?.clients?._links?.list && refreshClients) {
          setRefreshClients(false)
          setSelectedClients([])
          const listLink = pageChange ?? menu.items.clients._links.list

          const uriParameters: Record<string, string> = {
            searchTerm,
            sortField,
            sortOrder,
          }

          if (filterModel?.items.length) {
            uriParameters.filterField = filterModel.items[0].field
            uriParameters.filterOperator = filterModel.items[0].operator
            // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
            uriParameters.filterValue = filterModel.items[0].value
          }

          const [pagedClients, error] =
            await boommedService.fetch<SuiteApi.ClientsListResponse>(
              listLink,
              undefined,
              uriParameters,
            )

          if (error) {
            setUnexpectedError(error)
            return
          }

          setPageChange(null)
          setClients(pagedClients)

          if (pagedClients) {
            setSelectedPage(pagedClients.page.currentPage - 1)
          }
        }
      },
    },
    [menu, refreshClients, searchTerm],
  )

  const deleteSelectedClients = useCallback(async () => {
    if (clients) {
      const [_, error] = await boommedService.fetch(clients._links.delete, {
        ids: selectedClients.map((client) => client.id),
      })

      if (error) {
        setUnexpectedError(error)
        return
      }

      setRefreshClients(true)
    }
  }, [selectedClients])

  const masterActions = useMemo(() => {
    const actions: MasterPageAction[] = []

    // NOTE: Hack to avoid showing the new button in HVSB tenant
    const displayNewButton =
      clients?._links.new &&
      tenant?.id !== '669522be-3aec-4f7f-86c2-678d9ecd6969'

    if (displayNewButton && tenant?.id) {
      actions.push({
        title: t('add'),
        icon: <AddIcon />,
        href: buildNavigationPath(AppRoutes.clientAdd, {
          tenantId: tenant?.id,
        }),
      })
    }

    const displayDeleteButton =
      selectedClients.length &&
      !selectedClients.find((client) => !client._links.delete)

    if (displayDeleteButton) {
      actions.push({
        title: t('delete'),
        icon: <DeleteIcon />,
        sx: styles.deleteButton,
        onClick: deleteSelectedClients,
      })
    }
    return actions
  }, [clients, selectedClients, tenant])

  const onSelectionChange = useCallback(
    (selectedItems: GridRowSelectionModel) => {
      const filteredClients = clients?.page.elements.filter((client) =>
        selectedItems.includes(client.id),
      )
      setSelectedClients(filteredClients ?? [])
    },
    [clients],
  )
  const onPageChange = useCallback(
    (page: number) => {
      const currentPage = clients?.page.currentPage ?? -1

      if (
        clients?._links.nextPage &&
        currentPage >= 0 &&
        currentPage < page + 1
      ) {
        setPageChange(clients._links.nextPage)
      } else if (clients?._links.previousPage) {
        setPageChange(clients._links.previousPage)
      } else {
        setPageChange(null)
      }
      setSelectedPage(page)
      setRefreshClients(true)
    },
    [clients, selectedPage],
  )

  const onSortChange = useCallback(
    (sortModel: GridSortModel) => {
      setSortField(sortModel[0]?.field ?? Strings.empty())
      setSortOrder(sortModel[0]?.sort ?? Strings.empty())
      setRefreshClients(true)
    },
    [sortField, sortOrder],
  )

  const onFilterChange = useCallback(
    (filterModel: GridFilterModel) => {
      const filters = filterModel.items.length ? filterModel : undefined

      setFilterModel(filters)
      setRefreshClients(true)
    },
    [filterModel],
  )

  const onSearchChange = useCallback((value: string) => {
    setSearchTerm(Strings.isNullOrEmpty(value) ? Strings.empty() : value)
  }, [])

  const paginationLabels = (type: string) => t(`${type}_page`)

  const paginationText = ({ from, to, count }) =>
    `${t('page', {
      page: selectedPage + 1,
    })}, ${t('items')}: ${from}-${to} ${t('of')} ${count} `

  const pageSize = clients?.page.pageSize ?? 20

  return (
    <MasterPage title={t('clients')} menuOpen={true} actions={masterActions}>
      <Paper component="form" style={styles.searchBar}>
        <InputBase
          placeholder={t('clients_search_placeholder')}
          onChange={(event) => {
            onSearchChange(event.target.value)
          }}
          value={searchTerm}
          style={styles.searchBardInput}
          onKeyDown={(event) => {
            if (event.key === 'Enter') {
              event.preventDefault()
              setRefreshClients(true)
            }
          }}
          autoFocus={true}
        />
        {Strings.notEmpty(searchTerm) ? (
          <IconButton
            type="button"
            style={styles.searchBarIcon}
            aria-label={t('clients_search_clear')}
            onClick={() => {
              setSearchTerm(Strings.empty())
              setRefreshClients(true)
            }}
          >
            <ClearIcon />
          </IconButton>
        ) : null}
        <IconButton
          type="button"
          style={styles.searchBarIcon}
          aria-label={t('clients_search_placeholder')}
          onClick={() => {
            setRefreshClients(true)
          }}
        >
          <SearchIcon />
        </IconButton>
      </Paper>
      <DataGrid
        loading={fetching}
        sx={styles.root}
        columnBufferPx={5}
        rows={clients?.page.elements ?? []}
        onRowSelectionModelChange={onSelectionChange}
        onRowDoubleClick={({
          row: client,
        }: GridRowParams<SuiteApi.ClientDetailResponse>) => {
          if (client.externalLink) {
            window.open(client.externalLink, '_blank')
          }
        }}
        sortingMode="server"
        onSortModelChange={onSortChange}
        filterMode="server"
        onFilterModelChange={onFilterChange}
        filterDebounceMs={1000}
        columns={[
          {
            field: 'name',
            headerName: t('name'),
            align: 'left',
            headerAlign: 'left',
            flex: 1,
            renderCell: (params) => {
              return params.row.externalLink ? (
                <MaterialLink
                  href={params.row.externalLink}
                  rel="noopener noreferrer"
                >
                  {params.row.name}
                </MaterialLink>
              ) : (
                params.row.name
              )
            },
          },
          {
            field: 'nif',
            headerName: t('nif'),
            flex: 1,
          },
          {
            field: 'phoneNumber',
            headerName: t('contact'),
            flex: 1,
          },
          {
            field: 'notes',
            headerName: t('notes'),
            flex: 1,
          },
        ]}
        checkboxSelection
        pageSizeOptions={[pageSize]}
        autoHeight
        hideFooterPagination
        slots={{
          footer: () => (
            <Typography variant="h6" sx={styles.footer}>
              <div
                style={{
                  display: 'grid',
                }}
              >
                <TablePagination
                  rowsPerPageOptions={[pageSize]}
                  component="div"
                  page={selectedPage}
                  count={clients?.page.totalElements ?? 0}
                  rowsPerPage={pageSize}
                  onPageChange={(_, page) => {
                    onPageChange(page)
                  }}
                  getItemAriaLabel={paginationLabels}
                  labelDisplayedRows={paginationText}
                />
              </div>
            </Typography>
          ),
        }}
      />
    </MasterPage>
  )
}
